
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "VideoPlayer358301519",
      {"classes":{"root":"VideoPlayer358301519__root","playerContainer":"VideoPlayer358301519__playerContainer","facebookContainer":"VideoPlayer358301519__facebookContainer","cover":"VideoPlayer358301519__cover","vimeoContainer":"VideoPlayer358301519__vimeoContainer"},"vars":{},"stVars":{"backgroundColor":"rgba(var(--backgroundColor, 0,0,0), var(--alpha-backgroundColor, 1))","font":"var(--font)","fontColor":"rgba(var(--fontColor, 255,255,255), var(--alpha-fontColor, 1))","borderColor":"rgba(var(--borderColor, 0,0,0), var(--alpha-borderColor, 1))","borderSize":"var(--borderSize)","boxShadow":"var(--boxShadow)","playButtonSize":"var(--playButtonSize)","playButtonMobileSize":"var(--playButtonMobileSize)","textSizeScale":"'1px'"},"keyframes":{}},
      "",
      -1,
      module.id
  );
  