import React from 'react';

/** Hook to manage references asynchronously
 * @returns [waitForRef, getRef, setRef]
 * waitForRef: () => Promise<T> - returns promise to wait for reference to be set
 * getRef: () => T - returns refValue itself. Can be undefined in case value haven't been set yet
 * setRef: (T) => void - by calling this function you resolve the wait promise and set ref value
 */
export const useAsyncRef = <T>(): [
  () => Promise<T>,
  () => T | void,
  (value: T) => void,
] => {
  const valueRef = React.useRef<T | void>();
  const resolvesRef = React.useRef<Array<Function>>([]);

  const waitForValue = (): Promise<T> => {
    if (valueRef.current !== undefined) {
      return Promise.resolve(valueRef.current);
    }
    return new Promise<T>(resolve => {
      resolvesRef.current.push(resolve);
    });
  };

  const setValue = (value: T): void => {
    valueRef.current = value;
    resolvesRef.current.forEach(resolve => resolve(value));
    resolvesRef.current = [];
  };

  const getValue = () => valueRef.current;

  return [waitForValue, getValue, setValue];
};
