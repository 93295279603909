import * as React from 'react';
import {
  IVideoPlayerHandles,
  VideoPlayerProps,
  VideoPlayerCorvidState,
} from '../VideoPlayer.types';

import { useAsyncRef } from '../../../providers/useAsyncRef';
import { useHasBeenInViewport } from './utils/useHasBeenInViewport';
import VideoPlayers from './players';
import { IPlayerHandles } from './players/players.types';
import { st, classes } from './style/VideoPlayer.st.css';

import { getPlayerName } from './utils';

const VideoPlayer: React.ForwardRefRenderFunction<
  IVideoPlayerHandles,
  VideoPlayerProps
> = (props: VideoPlayerProps, ref) => {
  const { onMouseEnter, onMouseLeave, reducedMotion } = props;
  const autoplay = reducedMotion ? false : props.autoplay;
  const [reloadId, setReloadId] = React.useState<number>(0);
  const [isLoadingForcedByCorvid, setIsLoadingForcedByCorvid] = React.useState<
    boolean
  >(false);
  const playerName = getPlayerName(props.src);
  const isPlayable = playerName === 'playable';
  const Player = VideoPlayers[playerName];

  const reloadPlayer = React.useCallback(() => setReloadId(reloadId + 1), [
    reloadId,
  ]);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const playerRef = React.useRef<IPlayerHandles>(null);
  const [waitForPlayer, getPlayer, setPlayer] = useAsyncRef<IPlayerHandles>();

  const updateCorvidState = (override?: VideoPlayerCorvidState) => {
    if (!props.updateState) {
      return;
    }
    const overrideProps = override || {};
    const player = getPlayer();
    const playerProps = player
      ? {
          isPlaying: player.isPlaying(),
          duration: player.getDuration(),
        }
      : {};
    const newProps = {
      ...playerProps,
      ...overrideProps,
    };
    props.updateState(newProps);
  };

  React.useImperativeHandle(ref, () => ({
    play: () => {
      setIsLoadingForcedByCorvid(true);
      return waitForPlayer().then(player => player.play());
    },
    pause: () => waitForPlayer().then(player => player.pause()),
    stop: () =>
      waitForPlayer().then(player =>
        player.stop ? player.stop() : reloadPlayer(),
      ),
    togglePlay: () => {
      setIsLoadingForcedByCorvid(true);
      return waitForPlayer().then(player => player.togglePlay());
    },
    mute: () => {
      updateCorvidState({ isMuted: true });
      return waitForPlayer().then(player => player.mute());
    },
    unmute: () => {
      updateCorvidState({ isMuted: false });
      return waitForPlayer().then(player => player.unMute());
    },
    seek: (timePoint: number) =>
      waitForPlayer().then(player => player.seekTo(timePoint)),
    setVolume: (volume: number) => {
      updateCorvidState({ volume });
      waitForPlayer().then(player => player.setVolume(volume));
      // synchronous void return
    },
  }));

  const handleDuration = (duration: number) => {
    updateCorvidState({ duration });
  };

  const handlePlay = () => {
    const player = getPlayer() as IPlayerHandles;
    updateCorvidState({ isPlaying: player.isPlaying() });
    props.onPlay?.({ type: 'onPlay' });
  };

  const handlePause = () => {
    const player = getPlayer() as IPlayerHandles;
    updateCorvidState({ isPlaying: player.isPlaying() });
    props.onPause?.({ type: 'onPause' });
  };

  const handleEnded = () => {
    const player = getPlayer() as IPlayerHandles;
    updateCorvidState({ isPlaying: player.isPlaying() });
    props.onEnded?.({ type: 'onEnded' });
  };

  const handleProgress = (currentTime: number) => {
    const player = getPlayer() as IPlayerHandles;
    const volume = player.getVolume();
    const isMuted = player.isMuted();
    props.onStateUpdated?.({
      type: 'onStateUpdated',
      currentTime,
      volume,
      isMuted,
    });
    props.onProgress?.({ type: 'onProgress' });
  };

  const handleInit = () => {
    setPlayer(playerRef.current as IPlayerHandles);
  };

  const hasBeenInViewport = useHasBeenInViewport(containerRef);
  const shouldRenderPlayer =
    autoplay || hasBeenInViewport || isLoadingForcedByCorvid;

  return shouldRenderPlayer ? (
    <div
      id={props.id}
      className={st(classes.root)}
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Player
        key={`player-${props.src}-${reloadId}`}
        {...props}
        {...(isPlayable && props.playableConfig)}
        playing={autoplay}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onInit={handleInit}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        ref={playerRef}
      />
    </div>
  ) : (
    <div id={props.id} className={st(classes.root)} ref={containerRef} />
  );
};

export default React.forwardRef(VideoPlayer);
